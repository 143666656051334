import React, { useState } from 'react';
import hexalogo from "./logo.png";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Webcam from 'react-webcam'
import insert_image from './insert_image.png'
import { FiUser } from "@react-icons/all-files/fi/FiUser";
import { MdEmail } from "@react-icons/all-files/md/MdEmail";
import { AiFillMobile } from "@react-icons/all-files/ai/AiFillMobile";
import { Row, Col } from 'react-bootstrap'
import { Navigate } from 'react-router-dom';
const WebcamComponent = () => <Webcam />
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user',
}
const Dashboard = () => {
  const [picture, setPicture] = useState(insert_image)
  const [open, setOpen] = useState('');
  const [count, setCount] = useState(1);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const webcamRef = React.useRef(null)
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()
    setPicture(pictureSrc)
    setForm({
      ...form,
      ['image_id']: pictureSrc
    })
  })

  const [form, setForm] = useState({})

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
  }
  const addrecord = async () => {
    await fetch('https://5yhhxoivn8.execute-api.us-east-1.amazonaws.com/v1/put-data', {
      method: 'POST',
      body: JSON.stringify({
        'name': form.name,
        'email': form.email,
        'mobile': form.mobile,
        'image_id': form.image_id,
        'purpose_of_visit': form.purpose_of_visit,
        'contactPerson': form.contactPerson
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        alert("successfully saved");
        <Navigate replace to='/' />
      })
      .catch((err) => {
        console.log("error", err.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("values", form)
    if (form.image_id) {
      addrecord();
      setPicture(insert_image);
      setForm({});
      setOpen(false);
      setCount(1);
      event.target.reset();
    }
    else {
      alert("Please Take image using your web Cam")
    }
  };
  const nextTab = () => {
    if (!form.name) {
      setError('name');
      setMessage('Please Enter your name !')
      return;
    }
    if (!form.email) {
      setError('email');
      setMessage('Please Enter your Email!')
      return;
    }
    if (!form.mobile) {
      setError('mobile');
      setMessage('Please Enter your mobile no !')
      return;
    }
    if (!form.image_id) {
      setError('picture');
      setMessage('Please Take Your Photo through WebCam !')
      return;
    }

    setCount(count + 1)
  }
  return (
    <div style={{ width: '99%' }}>
      <Row style={{ background: 'whitesmoke' }}>
        <Col> <img src={hexalogo} style={{ cursor: 'pointer', padding: '10px', marginLeft: '10px' }} /></Col>
      </Row>

      <div className="m-2">
        <div className="row " style={{ marginLeft: '5rem', marginTop: '10rem' }} >
          <div className="col-xs-6 col-md-6">
            <div className='row mb-1 mt-1'>
              <h3>Enter your details</h3>
            </div>
            <Form onSubmit={handleSubmit} name='form' id='visited-user-id'>
              {count == 1 && <div>
                <Form.Group className="mb-2 d-flex" controlId="name">
                  <FiUser size={50} style={{ marginRight: '10px' }} />
                  <Form.Control type="text" placeholder="Enter Your Name" required
                    onChange={e => setField('name', e.target.value)} autoComplete='off' />
                </Form.Group>
                <div className='ms-5 mb-3'><span className='text-danger' >{error == 'name' && message}</span>  </div>
                <Form.Group className="mb-2 d-flex" controlId="email">
                  <MdEmail size={50} style={{ marginRight: '10px' }} />
                  <Form.Control type="email" placeholder="Enter  Your email" required
                    onChange={e => setField('email', e.target.value)} />
                </Form.Group>
                <div className='ms-5 mb-3'><span className='text-danger' >{error == 'email' && message}</span>  </div>
                <Form.Group className="mb-2 d-flex" controlId="mobile">
                  <AiFillMobile size={50} style={{ marginRight: '10px' }} />
                  <Form.Control type="text" placeholder="Enter your mobile number" required
                    onChange={e => setField('mobile', e.target.value)} />
                </Form.Group>
                <div className='ms-5 mb-3'><span className='text-danger' >{error == 'mobile' && message}</span>  </div>
              </div>}
              {count == 2 && <div>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>Contact Person</Form.Label>
                  <Form.Control type="text" placeholder="Enter Contact Person name " required
                    onChange={e => setField('contactPerson', e.target.value)} />
                </Form.Group>
              </div>}
              {count == 2 && <div>
                <Form.Group className="mb-3" controlId="purpose">
                  <Form.Label> Purpose to Visit</Form.Label>
                  <Form.Control type="text" placeholder="Purpose to visit" required
                    onChange={e => setField('purpose_of_visit', e.target.value)} />
                </Form.Group>
              </div>}
              <div className='text-end '>
                {/* {(count==2 || count ==3 ) &&
      <Button variant="primary"onClick={()=>{setCount(count - 1)}} >
        Prev
      </Button>
        } */}
                {(count == 1) &&
                  <Button className="primary btn-lg" onClick={nextTab} style={{ minWidth: '8rem' }} >
                    Next
                  </Button>
                }
                {(count == 2) &&
                  <Button variant="primary btn-lg" type="submit" style={{ minWidth: '8rem' }} >
                    Submit
                  </Button>
                }
              </div>
            </Form>
          </div>
          <div className='col-xs-1 col-md-1'></div>
          <div className='col-xs-5 col-md-5 ps-5' style={{ marginTop: '3rem' }}>
            {count == 1 &&
              <div>

                {!open ?
                  <div style={{ background: 'whitesmoke', width: '200px', height: '180px' }} >
                    <div className='p-2 display-6 text-center' style={{ height: '180px' }}> CLICK BELOW TO CAPTURE</div>
                    <div className='text-center mt-2'><button
                      onClick={(e) => {
                        setOpen(true)
                      }}
                      className="btn btn-danger"
                    >Take Image</button>
                      <div className='ms-4'><span className='text-danger' >{error == 'picture' && message}</span>  </div></div>
                  </div>
                  :
                  <div>
                    <div>
                      {picture == insert_image && (
                        <Webcam
                          audio={false}
                          height={180}
                          ref={webcamRef}
                          width={300}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                        />
                      )}
                    </div>
                    <div>
                      {picture != insert_image ? (
                        <div>
                          <div><img src={picture} style={{ width: '200px', height: '180px' }} />  </div>
                          <div className='mt-1' style={{ marginLeft: '6rem' }}>
                            <button
                              onClick={(e) => {
                                e.preventDefault()
                                setPicture(insert_image)
                              }}
                              className="btn btn-primary"
                            >
                              Retake
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div style={{ marginLeft: '6rem' }}>
                          <button
                            onClick={(e) => {
                              e.preventDefault()
                              capture()
                            }}
                            className="btn btn-danger"
                          >
                            Capture
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
        <div>


        </div>
      </div>
    </div>
  );
};

export default Dashboard;