import React from 'react';

const data = [
    { name: "Priyank Agarwal", id:'guest1', seq: '1. ' },
    { name: "Karan Khera", id:'guest2', seq: '2. '},
    { name: "Dinkar Bhatia", id:'guest3', seq: '3. '},
    { name: "Nitin Bhatia", id:'guest4', seq: '4. '}
  ]
  
  const onBtnClick = (id) => {
    document.getElementById(id).classList = " btn btn-success";
    document.getElementById(id).innerHTML="Approved";
  }
const List = () => {
    return (
        <div style={{marginTop: '10rem'}}>
        <div class="d-flex justify-content-around" >
        <table>
        <tr>
          <th>Name</th>
        </tr>
        {data.map((val, key) => {
          return (
            <tr key={key} style={{marginTop:'2rem', paddingBottom: '10px'}}>
              <td style={{paddingRight: '20px', fontSize: '20px'}}>{val.seq}{val.name}</td>
              <button class="btn btn-primary" style={{marginBottom:'10px'}} id={val.id} onClick={() => onBtnClick(val.id)} >Approve</button>
            </tr>
          )
        })}
      </table>
      </div>
      <div style={{marginTop: '2rem'}}>
      <div class="d-flex justify-content-around">
        <a class="btn btn-primary btn-lg col-md-2" href="/" role="button" style={{ minWidth: '8rem' }}>Back to Home</a>
      </div>
      </div>
      </div>
    )
};

export default List;
