import { Route,Routes } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Landing from  "./components/Landing";
import List from "./components/List";
import Thanks from "./components/Thanks";
import Widget from './components/Widget'
 function App() {
  return(  
    <div className="App">           
           <Routes>
                 <Route exact path='/' element={< Landing />}></Route>
                 <Route exact path='/list' element={< List />}></Route>
                 <Route exact path='/register' element={< Widget />}></Route>
                 <Route exact path='/thanks' element={< Thanks />}></Route>
          </Routes>
          </div>
  )
 }
 export default App;