import React, {useState} from 'react';



const Landing = () => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div style={{ marginTop: '10rem' }}>
      <div class="d-flex justify-content-around">
        <p class="display-1">Welcome To Hexaview</p>
      </div>
      <div class="d-flex justify-content-around">
        <a class="btn btn-primary btn-lg col-md-2" href="/register" role="button" style={{ minWidth: '8rem' }}>Register</a>
      </div>
      <div style={{marginTop:'10px'}}>
        <div class='d-flex justify-content-around'>
        <a class="btn btn-primary btn-lg col-md-2" href="/list" role="button" style={{ minWidth: '8rem' }}>View Guest List</a>
        </div>
        {/* {isOpen && <div class='d-flex justify-content-around' style={{marginTop: '2rem'}}> */}
        
          {/* {data.map((data) => {
            return 
          })}
          <div className='flex'><span  style={{marginRight: '20px'}}>Priyank Agarwal</span><button id='guest1'>Approve</button></div>
          <div className='flex'><span  style={{marginRight: '20px'}}>Karan Khera</span><button id='guest2'>Approve</button></div>
          <div className='flex'><span  style={{marginRight: '20px'}}>Dinkar Bhatia</span><button id='guest3'>Approve</button></div>
          <div className='flex'><span  style={{marginRight: '20px'}}>Nitin Bhatia</span><button id='guest4'>Approve</button></div> */}
          {/* </div>} */}
      </div>
    </div>
    
  )
};

export default Landing;
